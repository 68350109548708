import "core-js/modules/web.dom.iterable";
import { login as _login, registerA as _registerA, getInfo as _getInfo, logout } from '@/api/user';
import { getToken, setToken, removeToken, setUid, getUid, removeUid, getRoleid, setRoleid, removeRoleid, getRoleName, setRoleName, removeRoleName, setTeamID, removeTeamID, setDeptId, removeDeptID } from '@/utils/auth';
import { resetRouter } from '@/router'; // import router from '../../router'

var state = {
  token: getToken(),
  name: '',
  company: '',
  avatar: '',
  role: '',
  uid: getUid(),
  roleid: getRoleid(),
  rolename: getRoleName(),
  rightIds: [],
  teamID: '',
  // 用户所属团队ID
  deptId: '' // 用户所属部门ID

};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    // console.log('SET_TOKEN token: ' + token)
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    // console.log('SET_TOKEN name: ' + name)
    state.name = name;
  },
  SET_COMPANY: function SET_COMPANY(state, company) {
    // console.log('SET_TOKEN name: ' + name)
    state.company = company;
  },
  SET_ROLE: function SET_ROLE(state, role) {
    // console.log('SET_TOKEN name: ' + name)
    state.role = role;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_UID: function SET_UID(state, uid) {
    state.uid = uid;
  },
  SET_ROLEID: function SET_ROLEID(state, roleid) {
    state.roleid = roleid;
  },
  SET_ROLENAME: function SET_ROLENAME(state, rolename) {
    state.rolename = rolename;
  },
  SET_RIGHTID: function SET_RIGHTID(state, rightIds) {
    state.rightIds = rightIds;
  },
  SET_TEAMID: function SET_TEAMID(state, teamID) {
    state.teamID = teamID;
  },
  SET_DEPTId: function SET_DEPTId(state, deptId) {
    state.deptId = deptId;
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
        password = userInfo.password;
    var options = {
      username: username,
      password: password
    };
    return new Promise(function (resolve, reject) {
      _login(options).then(function (response) {
        // console.log(response)
        sessionStorage.clear();
        var res = response.data;
        commit('SET_TOKEN', res.data.token);
        commit('SET_NAME', username.trim());
        setToken(res.data.token);
        resolve(response);
      }).catch(function (error) {
        console.log('error' + error);
        reject(error);
      });
    });
  },
  // user register
  registerA: function registerA(_ref2, userInfo) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      _registerA({
        username: userInfo.ruleFormname.trim(),
        password: userInfo.ruleFormpass,
        role: 'admin',
        company: userInfo.region,
        secret_key: userInfo.cipher
      }).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      _getInfo().then(function (response) {
        var data = response.data.data;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        var username = data.username,
            avatar = data.avatar,
            role = data.role;
        commit('SET_NAME', username);
        commit('SET_ROLE', role);
        commit('SET_AVATAR', avatar);
        commit('SET_TEAMID', state.teamID);
        commit('SET_DEPTId', state.deptId);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
        state = _ref4.state,
        dispatch = _ref4.dispatch;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_NAME', '');
      commit('SET_ROLE', '');
      commit('SET_UID', '');
      commit('SET_ROLEID', '');
      commit('SET_ROLENAME', '');
      commit('SET_RIGHTID', []);
      commit('SET_TEAMID', '');
      commit('SET_DEPTId', '');
      window.localStorage.setItem('name', '');
      window.localStorage.setItem('company', '');
      sessionStorage.clear();
      removeToken();
      removeUid();
      resetRouter();
      removeRoleid();
      removeRoleName();
      removeTeamID();
      removeDeptID();
      dispatch('tagsView/delAllViews', null, {
        root: true
      });
      resolve();
    });
  },
  // remove token
  resetToken: function resetToken(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken();
      resolve();
    });
  },
  // 单点登录获取用户信息
  publicLogin: function publicLogin(_ref6, userInfo) {
    var commit = _ref6.commit;
    // console.log('publicLogin')
    return new Promise(function (resolve, reject) {
      _getInfo({
        roleid: state.roleid // 上海单点登录

      }).then(function (response) {
        // console.log(response, 'res')
        if (response.data.msgcde === '0000') {
          var rightList = response.data.rightlist;
          var roles = [];
          var rightIds = [];
          rightList.forEach(function (value) {
            rightIds.push(value.rightid);

            if (value.righturl !== '') {
              roles.push(value.righturl);
            }
          }); // console.log(rightIds, 'response.data.rightlist')

          commit('SET_RIGHTID', rightIds);
          localStorage.setItem('firstURL', roles[0]);
          commit('SET_ROLE', roles);
          response.roles = roles;
          resolve(response);
        } else {
          reject(response);
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  setUserInfo: function setUserInfo(_ref7, state) {
    var commit = _ref7.commit;
    // console.log('zhp haha setUserInfo')
    commit('SET_UID', state.uid);
    commit('SET_ROLEID', state.roleid);
    commit('SET_ROLENAME', state.rolename);
    commit('SET_TOKEN', state.token);
    commit('SET_NAME', state.username);
    commit('SET_TEAMID', state.teamID);
    commit('SET_DEPTId', state.deptId);
    window.localStorage.setItem('name', state.username);
    setUid(state.uid);
    setToken(state.token);
    setRoleid(state.roleid);
    setRoleName(state.rolename);
    setTeamID(state.teamID);
    setDeptId(state.deptId);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};