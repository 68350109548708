import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "D:/code/icsp-web-frame/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es6.regexp.constructor";

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * @param {string} str
 * @returns {Boolean}
 */

export function validUsername(str) {
  // const valid_map = ['admin', 'editor']
  // return valid_map.indexOf(str.trim()) >= 0
  return true;
}
/* 合法uri*/

export function validateURL(textval) {
  var urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return urlregex.test(textval);
}
/* 小写字母*/

export function validateLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}
/* 大写字母*/

export function validateUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}
/* 大小写字母*/

export function validatAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}
/* 是否包含中文*/

export function isContainChinese(str) {
  var lst = /[\u4e00-\u9fa5]/;
  return lst.test(str);
}
/* 是否是手机号*/

export function isMobilePhone(str) {
  var reg = new RegExp('^[\\d+-]*$');
  return reg.test(str);
}
export function checkPhone(str) {
  var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;

  if (!myreg.test(str)) {
    return false;
  } else {
    return true;
  }
}
export function checkSixNumber(str) {
  // var str = $("#numr").val().trim();
  if (str.length === 6) {
    var reg = /^\d{6}$/;

    if (!reg.test(str)) {
      return false;
    }
  } else {
    return false;
  }

  return true;
}
export function isPriceNumber(_keyword) {
  if (_keyword === '0' || _keyword === '0.' || _keyword === '0.0' || _keyword === '0.00') {
    _keyword = '0';
    return true;
  } else {
    var index = _keyword.indexOf('0');

    var length = _keyword.length;

    if (index === 0 && length > 1) {
      /* 0开头的数字串*/
      var reg = /^[0]{1}[.]{1}[0-9]{1,2}$/;

      if (!reg.test(_keyword)) {
        return false;
      } else {
        return true;
      }
    } else {
      /* 非0开头的数字*/
      var _reg = /^[1-9]{1}[0-9]{0,10}[.]{0,1}[0-9]{0,2}$/;

      if (!_reg.test(_keyword)) {
        return false;
      } else {
        return true;
      }
    }
  }
}
export function deepClone(obj) {
  var objClone = Array.isArray(obj) ? [] : {};

  if (obj && _typeof(obj) === 'object') {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] && _typeof(obj[key]) === 'object') {
          objClone[key] = deepClone(obj[key]);
        } else {
          objClone[key] = obj[key];
        }
      }
    }
  }

  return objClone;
}
/**
 * 判断是否为空
 * @param param 参数
 * @returns {boolean} 为空返回true，否则返回false
 */

export function isEmpty(param) {
  // null or undefined
  if (param === null || param === undefined) return true;
  if (typeof param === 'boolean') return false;
  if (typeof param === 'number') return false;
  if (param instanceof Error) return param.message === '';

  switch (Object.prototype.toString.call(param)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !param.length;
    // Map or Set or File

    case '[object File]':
    case '[object Map]':
    case '[object Set]':
      return !param.size;
    // Plain Object

    case '[object Object]':
      {
        return !Object.keys(param).length;
      }
  }

  return false;
}
export function isNotEmpty(obj) {
  return !isEmpty(obj);
}
/**
 * 校验经度
 * @returns {string|boolean}
 */

export function checkLongitude(longitude) {
  var longrg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,}|180)$/;
  return longrg.test(longitude);
}
/**
 * 校验纬度
 * @returns {string|boolean}
 */

export function checkLatitude(latitude) {
  var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,}|90\.0{0,}|[0-8]?\d{1}|90)$/;
  return latreg.test(latitude);
}
/**
 * 校验是否图片
 * @param file File
 * @returns {boolean}
 */

export function isImage(file) {
  if (file.type.toLowerCase().indexOf('image/jpg') === -1 && file.type.toLowerCase().indexOf('image/jpeg') === -1 && file.type.toLowerCase().indexOf('image/png') === -1) {
    return false;
  }

  return true;
}
/**
 * 校验不超过500kb
 * @param file File
 * @returns {boolean}
 */

export function isImageNoMoreThan500(file) {
  console.log('file', file);

  if (file.size >= 500 * 1024) {
    return false;
  }

  return true;
}
/**
 * 校验不超过800kb
 * @param file File
 * @returns {boolean}
 */

export function isImageNoMoreThan800(file) {
  console.log('file', file);

  if (file.size >= 800 * 1024) {
    return false;
  }

  return true;
}
/**
 * 校验不超过500kb
 * @param file File
 * @returns {boolean}
 */

export function noMoreThan500(file) {
  console.log('file', file);

  if (file.size >= 500 * 1024) {
    return false;
  }

  return true;
}
/**
 *  去字符串左右两端空格
 */

export function strTrim(str) {
  return str.replace(/(^\s*)|(\s*$)/g, '');
}
/**
 * 校验身份证号码是否合规 只要结果
 */

export function validTouristCardId(cardId) {
  if (cardId.length !== 18) {
    return false;
  }

  var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/; // 号码规则校验

  if (!format.test(cardId)) {
    return false;
  } // 区位码校验
  // 出生年月日校验   前正则限制起始年份为1900;


  var year = cardId.substr(6, 4); // 身份证年

  var month = cardId.substr(10, 2); // 身份证月

  var date = cardId.substr(12, 2); // 身份证日

  var time = Date.parse(month + '-' + date + '-' + year); // 身份证日期时间戳date

  var now_time = Date.parse(new Date()); // 当前时间戳

  var dates = new Date(year, month, 0).getDate(); // 身份证当月天数

  if (time > now_time || date > dates) {
    return false;
  } // 校验码判断


  var c = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]; // 系数

  var b = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']; // 校验码对照表

  var id_array = cardId.split('');
  var sum = 0;

  for (var k = 0; k < 17; k++) {
    sum += parseInt(id_array[k]) * parseInt(c[k]);
  }

  if (id_array[17].toUpperCase() !== b[sum % 11].toUpperCase()) {
    return false;
  }

  return true;
}
/**
 *  校验机构号
 * @param orgNo
 */

export function validateOrgNo(orgNo) {
  var reg = /^[A-Za-z0-9]+$/;
  return reg.test(orgNo);
}