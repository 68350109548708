/**
 * 接口URL地址
 */
var BASE_URL = '/icsp_sh'; // 自己的测试环境

export default {
  // 用户登录
  BAAS_login: BASE_URL + '/unlogin/login',
  // 用户登出
  BAAS_logout: BASE_URL + '/login/logout',
  // 获取用户信息
  BAAS_getUserInfo: BASE_URL + '/permission/queryPermission',
  // 首页数据
  HOME_DATA: BASE_URL + '/firstpg/tj4'
};