import Vue from 'vue';
import Router from 'vue-router';

var _import = require('./_import_' + process.env.NODE_ENV);

Vue.use(Router);
/* Layout */

import Layout from '@/layout';
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */

export var constantRouterMap = [{
  path: '/login',
  component: _import('login/index'),
  hidden: true
}, // 404 page must be placed at the end !!!
// { path: '*', redirect: '/login/welcomePage', hidden: true },
{
  path: '/404',
  component: _import('404'),
  hidden: true
}];
export var asyncRouterMap = [{
  path: '/homePage',
  component: Layout,
  meta: {
    title: '首页',
    icon: 'international',
    firstMenu: true
  },
  children: [{
    path: '/homePage/home',
    name: 'home',
    component: _import('home'),
    meta: {
      title: '首页',
      showRole: true
    }
  }]
}, {
  path: '/digitalCollections',
  component: Layout,
  meta: {
    title: '数字藏品',
    icon: 'international',
    firstMenu: true
  },
  children: [{
    path: '/digitalCollections/whiteList',
    name: 'whiteList',
    component: _import('digitalCollections/whiteList'),
    meta: {
      title: '白名单管理',
      showRole: true
    }
  }, {
    path: '/digitalCollections/nftToken',
    name: 'nftToken',
    component: _import('digitalCollections/nftToken'),
    meta: {
      title: '兑换码管理',
      showRole: true
    }
  }, {
    path: '/digitalCollections/displayNft',
    name: 'displayNft',
    component: _import('digitalCollections/displayNft'),
    meta: {
      title: '上下架管理',
      showRole: true
    }
  }, {
    path: '/digitalCollections/issueNft',
    name: 'issueNft',
    component: _import('digitalCollections/issueNft'),
    meta: {
      title: '藏品发行',
      showRole: true
    }
  }]
}];

var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: asyncRouterMap
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;