import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import _objectSpread from "D:/code/icsp-web-frame/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import pathToRegexp from 'path-to-regexp';
import { mapGetters } from 'vuex';
export default {
  data: function data() {
    return {
      levelList: null
    };
  },
  computed: _objectSpread({}, mapGetters(['isOrgMenu'])),
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  filters: {
    nameFilter: function nameFilter(val) {
      if (val.indexOf('● ') > -1) {
        var title = val.split('● ');
        return title[1];
      } else return val;
    }
  },
  methods: {
    // handleLink(item){
    //   const {params} = this.$route
    //   var toPath = pathToRegexp.compile(item.path)
    //   var toWhere = toPath(params)
    //   this.$router.push(toWhere)
    // },
    getBreadcrumb: function getBreadcrumb() {
      // only show routes with meta.title
      var baseMatched = this.$route.matched.filter(function (item) {
        return item.meta && item.meta.title;
      }); //1228注释
      // console.log(baseMatched)

      var matched = baseMatched; //1228注释

      var first = matched[0]; //1228注释

      if (!this.isDashboard(first)) {
        //1228注释
        if (baseMatched[1]) {
          //1228注释
          if (baseMatched[1].name === 'AllianceNode') {
            //1228注释
            // localStorage.setItem('isOrgMenu', 'true')
            this.$store.dispatch('app/changeNavRoute', {
              val: 'true'
            }); //1228注释
          } //1228注释

        } //1228注释
        // matched = [{ path: '/dashboard/homePage', meta: { title: '首页' }}].concat(baseMatched)
        // 以下部分试试是否能删除
        // if (matched[0].name !== 'AllianceAisle' && matched[0].name !== 'Homepage' && localStorage.getItem('isOrgMenu') === 'true') {
        //   if (baseMatched[1].name === 'AllianceNode') {
        //     matched = [].concat(baseMatched)
        //   } else {
        //     matched = [{ path: '/dashboard/allianceList', meta: { title: '联盟' }}, { path: '/dashboard/allianceNode', meta: { title: '联盟详情' }}].concat(baseMatched[1])
        //   }
        // } else {
        //   matched = [].concat(baseMatched)
        // }
        // 以上部分试试是否能删除

      }

      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      }); //       console.log(this.levelList, 'lwj this.levelList mianbaoxie')
    },
    isDashboard: function isDashboard(route) {
      var name = route && route.name;

      if (!name) {
        return false;
      }

      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase();
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
          path = item.path;

      if (redirect) {
        this.$router.push(redirect);
        return;
      }

      this.$router.push(this.pathCompile(path));
    }
  }
};