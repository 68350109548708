import "core-js/modules/es6.number.parse-int";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.constructor";
import "core-js/modules/es6.regexp.match";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es7.string.pad-start";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import _typeof from "D:/code/icsp-web-frame/node_modules/@babel/runtime/helpers/esm/typeof";
// username

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  var format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{([ymdhisa])+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    return value.toString().padStart(2, '0');
  });
  return time_str;
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */

export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return '刚刚';
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前';
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前';
  } else if (diff < 3600 * 24 * 2) {
    return '1天前';
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
  }
}
/**
 * @param {string} url
 * @returns {Object}
 */

export function param2Obj(url) {
  var search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}
/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */

export function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;

  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp; // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait

    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null; // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用

      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout; // 如果延时不存在，重新设定延时

    if (!timeout) timeout = setTimeout(later, wait);

    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}
/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */

export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'));
}
/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */

export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls;
}
/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */

export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
  }
}
/**
 * Date:2020/4/15
 * Desc:
 */
//

export function uuid() {
  // return (+new Date() * 1e6 + Math.floor(Math.random() * 1e6)).toString(36);
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0;
    var v = c == 'x' ? r : r & 0x3 | 0x8;
    return v.toString(16);
  });
} //

export function clone(obj) {
  var result = ''; //

  if (_typeof(obj) === 'object') {
    result = JSON.stringify(obj);
    result = JSON.parse(result);
  } else {
    result = obj;
  }

  return result;
}
export function randomSet(min, max) {
  var num = Math.floor(Math.random() * (max - min + 1)) + min;
  return num;
}
export function currentTime() {
  var aData = new Date();
  var year = aData.getFullYear();
  var month = aData.getMonth() < 9 ? '0' + (aData.getMonth() + 1) : aData.getMonth() + 1;
  var day = aData.getDate() <= 9 ? '0' + aData.getDate() : aData.getDate();
  return {
    year: year,
    month: month,
    day: day
  };
}
export function creatTime(time) {
  var year = time.substring(0, 4);
  var month = time.substring(5, 7);
  var day = time.substring(8, 10);
  var currenttime = currentTime();

  if (Number(year) === Number(currenttime.year)) {
    if (Number(month) === Number(currenttime.month)) {
      if (Number(day) === Number(currenttime.day)) {
        return '今天' + time.slice(10);
      } else if (Number(currenttime.day) - Number(day) === 1) {
        return '昨天' + time.slice(10);
      } else if (Number(currenttime.day) - Number(day) === 2) {
        return '前天' + time.slice(10);
      }
    }

    return time.slice(5);
  } else {
    return time.slice(2);
  } // console.log(currenttime)

}
export function formatMapData(data) {
  var mydata = [{
    name: '北京',
    selected: false,
    value: 0
  }, {
    name: '天津',
    selected: false,
    value: 0
  }, {
    name: '上海',
    selected: false,
    value: 0
  }, {
    name: '重庆',
    selected: false,
    value: 0
  }, {
    name: '河北',
    selected: false,
    value: 0
  }, {
    name: '河南',
    selected: false,
    value: 0
  }, {
    name: '云南',
    selected: false,
    value: 0
  }, {
    name: '辽宁',
    selected: false,
    value: 0
  }, {
    name: '黑龙江',
    selected: false,
    value: 0
  }, {
    name: '湖南',
    selected: false,
    value: 0
  }, {
    name: '安徽',
    selected: false,
    value: 0
  }, {
    name: '山东',
    selected: false,
    value: 0
  }, {
    name: '新疆',
    selected: false,
    value: 0
  }, {
    name: '江苏',
    selected: false,
    value: 0
  }, {
    name: '浙江',
    selected: false,
    value: 0
  }, {
    name: '江西',
    selected: false,
    value: 0
  }, {
    name: '湖北',
    selected: false,
    value: 0
  }, {
    name: '广西',
    selected: false,
    value: 0
  }, {
    name: '甘肃',
    selected: false,
    value: 0
  }, {
    name: '山西',
    selected: false,
    value: 0
  }, {
    name: '内蒙古',
    selected: false,
    value: 0
  }, {
    name: '陕西',
    selected: false,
    value: 0
  }, {
    name: '吉林',
    selected: false,
    value: 0
  }, {
    name: '福建',
    selected: false,
    value: 0
  }, {
    name: '贵州',
    selected: false,
    value: 0
  }, {
    name: '广东',
    selected: false,
    value: 0
  }, {
    name: '青海',
    selected: false,
    value: 0
  }, {
    name: '西藏',
    selected: false,
    value: 0
  }, {
    name: '四川',
    selected: false,
    value: 0
  }, {
    name: '宁夏',
    selected: false,
    value: 0
  }, {
    name: '海南',
    selected: false,
    value: 0
  }, {
    name: '台湾',
    selected: false,
    value: 0
  }, {
    name: '香港',
    selected: false,
    value: 0
  }, {
    name: '澳门',
    selected: false,
    value: 0
  }, {
    name: '南海诸岛',
    selected: false,
    value: 0
  }];

  for (var i = 0; i < data.length; i++) {
    if (data[i].regionname === '上海') {
      mydata[2].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '北京') {
      mydata[0].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '天津') {
      mydata[1].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '重庆') {
      mydata[3].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '河北') {
      mydata[4].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '河南') {
      mydata[5].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '云南') {
      mydata[6].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '辽宁') {
      mydata[7].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '黑龙江') {
      mydata[8].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '湖南') {
      mydata[9].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '安徽') {
      mydata[10].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '山东') {
      mydata[11].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '新疆') {
      mydata[12].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '江苏') {
      mydata[13].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '浙江') {
      mydata[14].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '江西') {
      mydata[15].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '湖北') {
      mydata[16].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '广西') {
      mydata[17].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '甘肃') {
      mydata[18].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '山西') {
      mydata[19].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '内蒙古') {
      mydata[20].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '陕西') {
      mydata[21].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '吉林') {
      mydata[22].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '福建') {
      mydata[23].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '贵州') {
      mydata[24].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '广东') {
      mydata[25].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '青海') {
      mydata[26].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '西藏') {
      mydata[27].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '四川') {
      mydata[28].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '宁夏') {
      mydata[29].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '海南') {
      mydata[30].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '台湾') {
      mydata[31].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '香港') {
      mydata[32].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '澳门') {
      mydata[33].value = Number.parseInt(data[i].regionnum);
    } else if (data[i].regionname === '南海诸岛') {
      mydata[34].value = Number.parseInt(data[i].regionnum);
    }
  }

  return mydata;
}
export function get_uuid() {
  var s = [];
  var hexDigits = '0123456789abcdef';

  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }

  s[14] = '4';
  s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = '-';
  var uuid = s.join('');
  return uuid;
}