import collection_service from '@/utils/collections_request'; // 白名单文件分页查询

export function queryWhiteListFileByPage(params) {
  return collection_service({
    url: '/whiteList/queryWhiteListFileByPage',
    method: 'POST',
    data: params
  });
} // 白名单复核

export function approveWhiteListFile(params) {
  return collection_service({
    url: '/whiteList/approveWhiteListFile',
    method: 'POST',
    data: params
  });
} // 查询藏品类型

export function queryAllNftTypeByPage(params) {
  return collection_service({
    url: '/issueTask/queryAllNftTypeByPage',
    method: 'POST',
    data: params
  });
} // 添加白名单

export function addWhiteListFile(params) {
  return collection_service({
    url: '/whiteList/uploadWhiteListFile',
    method: 'POST',
    data: params
  });
} // 生成兑换码

export function createNftToken(params) {
  return collection_service({
    url: '/issueTask/createNftToken',
    method: 'POST',
    data: params
  });
} // 下载兑换码

export function downloadNftToken(params) {
  return collection_service({
    url: '/issueTask/downloadNFTToken',
    method: 'POST',
    data: params,
    responseType: 'blob'
  });
} // 上下架管理

export function displayNft(params) {
  return collection_service({
    url: '/issueTask/displayNft',
    method: 'POST',
    data: params
  });
} // 藏品发行经办

export function handleIssueNft(params) {
  return collection_service({
    url: '/issueTask/handleIssueNft',
    method: 'POST',
    data: params
  });
} // 藏品发行复核

export function handleapproveIssueNft(params) {
  return collection_service({
    url: '/issueTask/approveIssueNft',
    method: 'POST',
    data: params
  });
} // 藏品进度查询（定时）

export function checkIssueProgress(params) {
  return collection_service({
    url: '/issueTask/checkIssueProgress',
    method: 'POST',
    data: params
  });
}