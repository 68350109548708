var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { height: "100%" } }, [
    _c(
      "div",
      {
        staticClass: "login-container",
        style: { backgroundImage: "url(" + _vm.img + ")" }
      },
      [
        _c("el-row", { staticStyle: { width: "700px" } }, [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { src: require("../../assets/login_form_bg.png") }
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "login-form" },
            [
              _c("img", {
                staticStyle: { width: "150px", margin: "12px" },
                attrs: { src: require("../../assets/login_icon_title.png") }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "font-family": "PingFangSC-Bold",
                    "font-size": "16px",
                    color: "rgba(255, 255, 255, 0.85)",
                    "text-align": "center",
                    "margin-top": "30px"
                  }
                },
                [_vm._v("登录")]
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  ref: "username",
                  staticStyle: {
                    position: "relative",
                    height: "40px",
                    width: "240px",
                    border: "2px",
                    "margin-top": "20px"
                  },
                  attrs: {
                    placeholder: "请输入用户名",
                    name: "username",
                    type: "text",
                    maxlength: 20,
                    tabindex: "1"
                  },
                  model: {
                    value: _vm.loginForm.username,
                    callback: function($$v) {
                      _vm.$set(_vm.loginForm, "username", $$v)
                    },
                    expression: "loginForm.username"
                  }
                },
                [
                  _c("img", {
                    staticClass: "input_icon",
                    attrs: {
                      slot: "prefix",
                      src: require("../../assets/icon_user@3x.png")
                    },
                    slot: "prefix"
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "el-input",
                {
                  ref: "password",
                  staticStyle: {
                    position: "relative",
                    height: "40px",
                    width: "240px",
                    border: "2px",
                    "margin-top": "20px"
                  },
                  attrs: {
                    placeholder: "请输入密码",
                    name: "password",
                    type: "password",
                    maxlength: 20,
                    tabindex: "1"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleLogin($event)
                    }
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password"
                  }
                },
                [
                  _c("img", {
                    staticClass: "input_icon",
                    attrs: {
                      slot: "prefix",
                      src: require("../../assets/icon_lock@3x.png")
                    },
                    slot: "prefix"
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "btn", on: { click: _vm.handleLogin } },
                [_vm._v("登录")]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "copyright" }, [
          _vm._v("2020 © 中银金融科技有限公司")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "copyright",
            staticStyle: { bottom: "15px", cursor: "pointer" },
            on: { click: _vm.goUrl }
          },
          [_vm._v("沪ICP备2021027980号-1")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }