import "core-js/modules/es6.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  isOrgMenu: function isOrgMenu(state) {
    return state.app.isOrgMenu;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  company: function company(state) {
    return state.user.company;
  },
  role: function role(state) {
    return state.user.role;
  },
  contentraw: function contentraw(state) {
    return state.message.contentraw;
  },
  uid: function uid(state) {
    return state.user.uid;
  },
  roleid: function roleid(state) {
    return state.user.roleid;
  },
  rolename: function rolename(state) {
    return state.user.rolename;
  },
  rightIds: function rightIds(state) {
    return state.user.rightIds;
  },
  addRoutes: function addRoutes(state) {
    return state.permission.addRoutes;
  },
  routes: function routes(state) {
    return state.permission.routes;
  },
  permission_routers: function permission_routers(state) {
    return state.permission.routes;
  }
};
export default getters;