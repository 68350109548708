var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        { staticClass: "pageClass" },
        [
          _c(
            "el-header",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                height: "auto"
              }
            },
            [
              _c("div", { staticClass: "numCard_blue" }, [
                _c(
                  "div",
                  {
                    staticStyle: { padding: "15px", cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.goTo("1")
                      }
                    }
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "cardText",
                          staticStyle: { "line-height": "20px" }
                        },
                        [_vm._v("客户数")]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "cardImg",
                        attrs: {
                          src: require("@/assets/homePage/arrow@3x.png")
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "8px",
                          "text-align": "right",
                          height: "40px"
                        }
                      },
                      [
                        _c("CountTo", {
                          staticClass: "count-value",
                          attrs: {
                            "start-val": _vm.countStart1,
                            "end-val": _vm.countEnd1,
                            duration: 3000
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "numCard_purple" }, [
                _c("div", { staticStyle: { padding: "15px" } }, [
                  _c("div", [
                    _c(
                      "span",
                      {
                        staticClass: "cardText",
                        staticStyle: { "line-height": "20px" }
                      },
                      [_vm._v("日触达量")]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-top": "8px",
                        "text-align": "right",
                        height: "40px"
                      }
                    },
                    [
                      _c("CountTo", {
                        staticClass: "count-value",
                        attrs: {
                          "start-val": _vm.countStart2,
                          "end-val": _vm.countEnd2,
                          duration: 3000
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "numCard_azure" }, [
                _c(
                  "div",
                  {
                    staticStyle: { padding: "15px", cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.goTo("2")
                      }
                    }
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "cardText",
                          staticStyle: { "line-height": "20px" }
                        },
                        [_vm._v("进行中的活动")]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "cardImg",
                        attrs: {
                          src: require("@/assets/homePage/arrow@3x.png")
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "8px",
                          "text-align": "right",
                          height: "40px"
                        }
                      },
                      [
                        _c("CountTo", {
                          staticClass: "count-value",
                          attrs: {
                            "start-val": _vm.countStart3,
                            "end-val": _vm.countEnd3,
                            duration: 3000
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "numCard_orange" }, [
                _c(
                  "div",
                  {
                    staticStyle: { padding: "15px", cursor: "pointer" },
                    on: {
                      click: function($event) {
                        return _vm.goTo("3")
                      }
                    }
                  },
                  [
                    _c("div", [
                      _c(
                        "span",
                        {
                          staticClass: "cardText",
                          staticStyle: { "line-height": "20px" }
                        },
                        [_vm._v("进行中的策略")]
                      ),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "cardImg",
                        attrs: {
                          src: require("@/assets/homePage/arrow@3x.png")
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "8px",
                          "text-align": "right",
                          height: "40px"
                        }
                      },
                      [
                        _c("CountTo", {
                          staticClass: "count-value",
                          attrs: {
                            "start-val": _vm.countStart4,
                            "end-val": _vm.countEnd4,
                            duration: 3000
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "el-main",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "align-items": "center",
                overflow: "hidden"
              }
            },
            [_c("home-page-animation")],
            1
          ),
          _vm._v(" "),
          _c(
            "el-footer",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                height: "auto"
              }
            },
            [
              _c("div", { staticClass: "iconText" }, [
                _c("img", {
                  staticStyle: { width: "80px", cursor: "pointer" },
                  attrs: { src: require("@/assets/homePage/icon1@3x.png") },
                  on: {
                    click: function($event) {
                      return _vm.goTo("4")
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticStyle: { "margin-top": "15px" } }, [
                  _vm._v("创建营销活动")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "iconText" }, [
                _c("img", {
                  staticStyle: { width: "80px", cursor: "pointer" },
                  attrs: { src: require("@/assets/homePage/icon2@3x.png") },
                  on: {
                    click: function($event) {
                      return _vm.goTo("5")
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticStyle: { "margin-top": "15px" } }, [
                  _vm._v("创建简单触达")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "iconText" }, [
                _c("img", {
                  staticStyle: { width: "80px", cursor: "pointer" },
                  attrs: { src: require("@/assets/homePage/icon3@3x.png") },
                  on: {
                    click: function($event) {
                      return _vm.goTo("6")
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticStyle: { "margin-top": "15px" } }, [
                  _vm._v("创建简单规则因子")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "iconText" }, [
                _c("img", {
                  staticStyle: { width: "80px", cursor: "pointer" },
                  attrs: { src: require("@/assets/homePage/icon4@3x.png") },
                  on: {
                    click: function($event) {
                      return _vm.goTo("7")
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticStyle: { "margin-top": "15px" } }, [
                  _vm._v("创建消息节点")
                ])
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }