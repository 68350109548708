import _objectSpread from "D:/code/icsp-web-frame/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from '@/styles/variables.scss';
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  // watch: {
  //   $route() {
  //     this.activeMenu()
  //   }
  // },
  created: function created() {//        console.log(this.permission_routers, 'this.$router.options.routes')
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['sidebar', 'isOrgMenu', 'permission_routers'])), {}, {
    routes: function routes() {
      return this.$router.options.routes;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // console.log(localStorage.getItem('isOrgMenu'), 'hahahah')
      // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};