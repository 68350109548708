var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("hamburger", {
        staticClass: "hamburger-container",
        staticStyle: { padding: "0px 6px 0px 30px" },
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar }
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container",
              attrs: { trigger: "click" },
              on: { command: _vm.handleCommand }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: require("@/assets/user_icon/user_icon1.png") }
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "margin-left": "5px",
                      "font-size": "14px",
                      color: "#1A1A1A"
                    }
                  },
                  [_vm._v(_vm._s(_vm._f("roleFilter")(_vm.name || _vm.roleid)))]
                ),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  staticStyle: {
                    "background-color": "#fcfcfc",
                    width: "212px"
                  },
                  attrs: { slot: "dropdown" },
                  slot: "dropdown"
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      staticStyle: {
                        "padding-left": "30px",
                        "border-bottom": "1px solid #eeeeee"
                      },
                      attrs: { disabled: "" }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "15px",
                          height: "15px",
                          "vertical-align": "text-bottom",
                          "margin-right": "10px"
                        },
                        attrs: { src: require("@/assets/icon_user.png") }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: { color: "#999999", "font-size": "14px" }
                        },
                        [_vm._v(_vm._s(_vm.rolename))]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    {
                      staticStyle: { "padding-left": "30px" },
                      attrs: { command: "b" }
                    },
                    [
                      _c("img", {
                        staticStyle: {
                          width: "15px",
                          height: "15px",
                          "vertical-align": "text-bottom",
                          "margin-right": "10px"
                        },
                        attrs: { src: require("@/assets/quit.png") }
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticStyle: { "font-size": "14px", color: "#1A1A1A" }
                        },
                        [_vm._v("退出登录")]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          height: "100%",
          width: "210px",
          float: "left",
          "padding-left": "31px",
          "line-height": "72px"
        }
      },
      [
        _c("img", {
          staticStyle: {
            width: "150px",
            "margin-right": "15px",
            "vertical-align": "middle",
            "margin-top": "-2px"
          },
          attrs: { src: require("@/assets/title.png") }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }