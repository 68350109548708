import axios from 'axios';
import { Message } from 'element-ui';
import router from '@/router/index';
import store from '@/store'; // import { getToken } from '@/utils/auth'
// create an axios instance

var collection_service = axios.create({});
collection_service.interceptors.request.use(function (config) {
  config.baseURL = '/management';
  config.headers['Accept'] = 'application/json;chartset=UTF-8;text-plain,*/*';
  config.headers['authorization'] = 'management'; // 替代token,后续要修改

  config.headers['token'] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2NDEzMjI0IiwiZXhwIjoxNjc0MDEzMDA0LCJpYXQiOjE2NzQwMDk0MDR9.MoTKP-A67Ypu9pIK5etugdNz-8A3OjI0Do9DWlP3ieU'; // config.headers['token'] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2NDEzMjI5IiwiZXhwIjoxNjczNTk3MDg3LCJpYXQiOjE2NzM1OTM0ODd9.yKlTMF2UkpfkvES0k4ZND8_bG-9pCia36mvp1pM3bG4'
  // if (store.getters.token) {
  //   config.headers['token'] = getToken()
  // }

  return config;
}, function (error) {
  return Promise.reject(error);
}); // response interceptor

collection_service.interceptors.response.use(function (response) {
  // return response
  var headers = response.headers;

  if (headers['content-type'] === 'application/force-download;charset=utf-8') {
    return response.data;
  }

  if (response.status === 200) {
    return response.data;
  }
}, function (error) {
  if (error.response.status === 401) {
    // 未授权，重新登录
    Message({
      message: error.response.data.message,
      type: 'error',
      duration: 2 * 1000
    });
    store.dispatch('user/logout').then(function () {
      router.push({
        path: '/login'
      });
    });
  } else if (error.response.status === 500 || error.response.status === 403) {
    Message({
      message: error.response.data.message || 'Error',
      type: 'error',
      duration: 2 * 1000
    });
    return Promise.reject(new Error(error.response.data.message || 'Error'));
  } else {
    return Promise.reject(error);
  }
});
export default collection_service;