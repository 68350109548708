var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "app-main" },
    [
      !_vm.$route.meta.keepAlive
        ? _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [
              _c(
                "keep-alive",
                { attrs: { include: _vm.cachedViews } },
                [_c("router-view", { key: _vm.key })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$route.meta.keepAlive
        ? _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [
              _c(
                "keep-alive",
                {
                  attrs: {
                    exclude:
                      "addSimpleStrategy,editSimpleStrategy,editMessage,addNewMessage,addMenu,editMenu"
                  }
                },
                [_c("router-view", { key: _vm.$route.name })],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }