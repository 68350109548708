var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        { staticClass: "pageClass" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  attrs: { data: _vm.tableData }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "nftTypeName",
                      label: "藏品名称",
                      "min-width": "8%",
                      width: "216"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "issueMember1",
                      label: "发行方",
                      "min-width": "8%",
                      width: "176"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realIssueTime",
                      label: "发行日期",
                      "min-width": "6%",
                      formatter: _vm.formateDate,
                      width: "112"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "toIssueAmount",
                      label: "发行数量",
                      "min-width": "5%",
                      width: "96"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "nftLabel1",
                      label: "标签",
                      "min-width": "6%",
                      width: "128"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.nftLabel1,
                                    expression: "scope.row.nftLabel1"
                                  }
                                ],
                                staticClass: "labelStyle"
                              },
                              [_vm._v(_vm._s(scope.row.nftLabel1))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "deptName",
                      label: "部门",
                      "min-width": "7%",
                      width: "128"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "teamName",
                      label: "团队",
                      "min-width": "7%",
                      width: "128"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contactName",
                      label: "联系人",
                      "min-width": "7%",
                      width: "88"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contactPhone",
                      label: "联系电话",
                      "min-width": "7%",
                      width: "112"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realName1",
                      label: "经办人",
                      "min-width": "7%",
                      width: "96"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "handleTime1",
                      label: "经办时间",
                      "min-width": "7%",
                      formatter: _vm.formateDate,
                      width: "112"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realName2",
                      label: "复核",
                      "min-width": "7%",
                      width: "96"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "handleTime2",
                      label: "复核时间",
                      "min-width": "7%",
                      formatter: _vm.formateDate,
                      width: "112"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "12%",
                      width: "176",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "background-color": "#f0f0f0",
                                  width: "92px",
                                  "border-radius": "12px"
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "dispalyStyle",
                                    class: {
                                      confirmStyle:
                                        scope.row.displayStatus === "OFF"
                                    },
                                    attrs: {
                                      disabled: scope.row.displayStatus === "ON"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.manageNft(scope.row, "ON")
                                      }
                                    }
                                  },
                                  [_vm._v("上架")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-popover",
                                  { attrs: { placement: "top", width: "256" } },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "你确定要下架该藏品吗？若下架用户将不能在数字藏品展馆看到该藏品。"
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "right",
                                          margin: "0"
                                        }
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn-plain",
                                            on: { click: _vm.closePopover }
                                          },
                                          [_vm._v("取消")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn-pri",
                                            on: {
                                              click: function($event) {
                                                return _vm.manageNft(
                                                  scope.row,
                                                  "OFF"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("确定")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "dispalyStyle",
                                        class: {
                                          confirmStyle:
                                            scope.row.displayStatus === "ON"
                                        },
                                        attrs: {
                                          slot: "reference",
                                          disabled:
                                            scope.row.displayStatus === "OFF"
                                        },
                                        slot: "reference"
                                      },
                                      [_vm._v("下架")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-footer",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                height: "auto"
              }
            },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": 10,
                  layout: "total,sizes, prev, pager, next, jumper",
                  total: _vm.tableData.length
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }