import _objectSpread from "D:/code/icsp-web-frame/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
import router from '@/router/index';
import { logout } from '@/api/user';
export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger
  },
  filters: {
    roleFilter: function roleFilter(value) {
      var str = '';

      switch (value) {
        case '1':
          str = '分行管理员';
          break;

        case '2':
          str = '活动策划员';
          break;

        case '3':
          str = '客户经理';
          break;

        default:
          str = value;
      }

      return str;
    }
  },
  data: function data() {
    return {
      name: localStorage.getItem('name')
    };
  },
  computed: _objectSpread({}, mapGetters(['sidebar', 'avatar', 'company', 'roleid', 'rolename'])),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    handleCommand: function handleCommand(command) {
      var _this = this;

      if (command === 'a') {// this.deleteDialogShow = true
      } else if (command === 'b') {
        logout().then(function (response) {
          _this.$store.dispatch('user/logout').then(function () {
            router.push({
              path: '/login'
            });
          });
        }).catch({});
      }
    }
  }
};