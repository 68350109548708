import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "D:\\code\\icsp-web-frame\\node_modules\\core-js\\modules\\es6.array.iterator.js";
import "D:\\code\\icsp-web-frame\\node_modules\\core-js\\modules\\es6.promise.js";
import "D:\\code\\icsp-web-frame\\node_modules\\core-js\\modules\\es6.object.assign.js";
import "D:\\code\\icsp-web-frame\\node_modules\\core-js\\modules\\es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import elCascaderMulti from 'el-cascader-multi'; // lang i18n

import '@/styles/index.scss'; // global css

import preventReClick from "./plugins";
import realImg from "./plugins";
import App from "./App";
import '@/icons'; // icon

import '@/permission'; // permission control

import * as filters from "./filters"; // global filters

import echarts from 'echarts';
Vue.prototype.$echarts = echarts; // 全局定义echarts挂载到Vue的原型上

import store from "./store";
import router from "./router";
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
// import { mockXHR } from '../mock'
// if (process.env.NODE_ENV === 'production') {
//   mockXHR()
// }

ElementUI.Dialog.props.closeOnClickModal.default = false; // 改变关闭模态的默认方法
// set ElementUI lang to EN

Vue.use(ElementUI, {
  locale: locale
}); // 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)

Vue.config.productionTip = false;
Vue.use(elCascaderMulti); // register global utility filters

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
new Vue({
  el: '#app',
  router: router,
  store: store,
  preventReClick: preventReClick,
  realImg: realImg,
  render: function render(h) {
    return h(App);
  }
});