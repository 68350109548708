var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper", class: _vm.classObj }, [
    _vm.device === "mobile" && _vm.sidebar.opened
      ? _c("div", {
          staticClass: "drawer-bg",
          on: { click: _vm.handleClickOutside }
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        class: { "fixed-header": _vm.fixedHeader },
        staticStyle: { height: "72px" }
      },
      [_c("navbar")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "main-container",
        class: { hasTagsView: _vm.needTagsView }
      },
      [
        _c(
          "div",
          [
            _c("sidebar", { staticClass: "sidebar-container" }),
            _vm._v(" "),
            _vm.needTagsView ? _c("tags-view") : _vm._e(),
            _vm._v(" "),
            _c("app-main")
          ],
          1
        ),
        _vm._v(" "),
        _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }