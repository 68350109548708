import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { validUsername } from '@/utils/validate';
import { login } from "../../api/user";
import { setToken, setUid, setRoleid, setRoleName } from '@/utils/auth';
import md5 from 'js-md5';
export default {
  name: 'Login',
  data: function data() {
    var _this = this;

    var validateUsername = function validateUsername(rule, value, callback) {
      // console.log(validUsername(value))
      if (!validUsername(value)) {
        callback(new Error('请输入账号'));
      } else {
        callback();
      }
    };

    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.indexOf(' ') !== -1) {
        callback(new Error('密码不得含有空格'));
      } else if (value.length < 6) {
        callback(new Error('密码不得少于6位'));
      } else {
        callback();
      }
    };

    var checkName = function checkName(rule, value, callback) {
      if (!value) {
        return callback(new Error('用户名不能为空'));
      }

      callback();
    };

    var validatePass = function validatePass(rule, value, callback) {
      if (value.indexOf(' ') !== -1) {
        callback(new Error('密码不得含有空格'));
      } else if (value === '' || value.length < 6) {
        callback(new Error('请输入密码或密码长度小于6位'));
      } else {
        if (_this.ruleForm.checkPass !== '') {
          _this.$refs.ruleForm.validateField('checkPass');
        }

        callback();
      }
    };

    var validatePass2 = function validatePass2(rule, value, callback) {
      if (value.indexOf(' ') !== -1) {
        callback(new Error('密码不得含有空格'));
      } else if (value === '' || value.length < 6) {
        callback(new Error('请再次输入密码或密码长度小于6位'));
      } else if (value !== _this.ruleForm.ruleFormpass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };

    return {
      restaurants: [],
      state1: '',
      state2: '',
      ruleForm: {
        ruleFormname: '',
        ruleFormpass: '',
        checkPass: '',
        region: '',
        cipher: ''
      },
      options: [],
      listQuery: {
        // 查询联盟
        currentPage: 1,
        pageSize: 99,
        company: '',
        // 所属公司
        createtime: '',
        // 选择日期
        alliancename: '' // 联盟名称

      },
      rules: {
        ruleFormpass: [{
          validator: validatePass,
          trigger: 'blur',
          required: true
        }],
        checkPass: [{
          validator: validatePass2,
          trigger: 'blur',
          required: true
        }],
        ruleFormname: [{
          validator: checkName,
          trigger: 'blur',
          required: true
        }],
        region: [{
          required: true,
          trigger: 'blur',
          message: '所属公司不能为空'
        }],
        cipher: [{
          required: true,
          trigger: 'blur',
          message: '公司密钥不能为空'
        }]
      },
      loginForm: {
        username: '',
        password: ''
      },
      loginRules: {
        username: [{
          required: true,
          trigger: 'blur',
          validator: validateUsername
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      dialogFormVisible: false,
      formLabelWidth: '120px',
      img: require("../../assets/login_background2.jpg")
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  mounted: function mounted() {
    setToken('');
    setUid('');
    setRoleid('');
    setRoleName(''); // this.handleLogin()

    if (!localStorage.getItem('userName')) {
      return;
    } else {
      this.restaurants = this.loadAll();
    }
  },
  methods: {
    goUrl: function goUrl() {
      window.location.href = 'https://beian.miit.gov.cn';
    },
    loadAll: function loadAll() {
      var data = localStorage.getItem('userName').split(',');
      var temp = [];
      data.forEach(function (c) {
        temp.push({
          value: c
        });
      });
      return temp;
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      // 过滤
      if (this.loginForm.username.length === 0) {
        this.$message({
          message: '请输入用户名',
          type: 'error'
        });
        return;
      }

      if (this.loginForm.password.length === 0) {
        this.$message({
          message: '请输入密码',
          type: 'error'
        });
        return;
      }

      var pwd = md5(this.loginForm.password);
      login({
        username: this.loginForm.username,
        password: pwd,
        usertype: 2,
        logintype: 1
      }).then(function (res) {
        if (res.data != null && res.data.msgcde === '0000') {
          setToken(res.data.token);
          setUid(res.data.uid);
          setRoleid(res.data.roleid);
          setRoleName(res.data.rolename);
          console.log(res.data.rolename, 'res.data.rolename');

          _this2.$store.dispatch('user/setUserInfo', res.data).then(function (res) {
            localStorage.setItem('loginType', '');

            _this2.$router.push({
              path: '/homePage/home'
            });
          });

          return;
        }

        _this2.$message({
          message: res.data.msg,
          type: 'error'
        });
      }).catch(function () {});
    }
  }
};