import Cookies from 'js-cookie';
var TokenKey = 'vue_admin_template_token';
var Tuid = 'UID';
var Troleid = 'roleid';
var Trolename = 'rolename';
var TteamId = 'teamId';
var TdeptId = 'deptId';
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token);
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}
export function getUid() {
  return Cookies.get(Tuid);
}
export function setUid(uid) {
  return Cookies.set(Tuid, uid);
}
export function removeUid() {
  return Cookies.remove(Tuid);
}
export function getRoleid() {
  return Cookies.get(Troleid);
}
export function setRoleid(uid) {
  return Cookies.set(Troleid, uid);
}
export function removeRoleid() {
  return Cookies.remove(Troleid);
}
export function getRoleName() {
  return Cookies.get(Trolename);
}
export function setRoleName(rname) {
  return Cookies.set(Trolename, rname);
}
export function removeRoleName() {
  return Cookies.remove(Trolename);
}
export function setTeamID(teamId) {
  return Cookies.set(TteamId, teamId);
}
export function removeTeamID() {
  return Cookies.remove(TteamId);
}
export function setDeptId(deptId) {
  return Cookies.set(TdeptId, deptId);
}
export function removeDeptID() {
  return Cookies.remove(TdeptId);
}