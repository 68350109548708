import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import router from '@/router/index';
import store from '@/store';
import { getToken, getUid, getRoleid } from '@/utils/auth'; // import qs from 'qs'
// create an axios instance

var service = axios.create({// baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 300000 // request timeout
}); // axios.defaults.headers['Content-Type'] = 'application/json;chartset=UTF-8'
// axios.defaults.transformRequest = [obj => qs.stringify(obj)]
// request interceptor

service.interceptors.request.use(function (config) {
  // do something before request is sent
  // console.log('config!!a')
  // config.headers['Content-Type'] = 'application/json'
  config.headers['Accept'] = 'application/json;chartset=UTF-8;text-plain,*/*'; // if (localStorage.getItem('requestForm') === '1') {
  //     // console.log('upload')
  //     config.headers['Content-Type'] = 'multipart/form-data'
  // } else {
  //     config.headers['Content-Type'] = 'application/json'
  // }
  // config.headers['Content-Type'] = 'multipart/form-data'

  config.headers['chnflg'] = '2';
  config.headers['clientid'] = '200';
  config.headers['usertype'] = '2';
  config.headers['code'] = 'hsdf7fgGfg723j9g';

  if (store.getters.token) {
    config.headers['token'] = getToken();
    config.headers['Authorization'] = 'Bearer ' + getToken();
  } // if (store.getters.name) {
  //     config.headers['username'] = store.getters.name
  // }


  if (store.getters.uid) {
    config.headers['uid'] = getUid();
  }

  if (store.getters.roleid) {
    config.headers['roleid'] = getRoleid();
  }

  config.headers['version'] = 44;
  config.headers['logflg'] = '2';
  return config;
}, function (error) {
  // do something with request error
  // console.log(error) // for debug
  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

/**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
function (response) {
  // return response
  localStorage.setItem('requestForm', '0');
  var res = response.data;
  var headers = response.headers;

  if (headers['content-type'] === 'application/octet-stream;charset=UTF-8') {
    return response;
  } // console.log('response!!11' + res.code)
  // if the custom code is not 20000, it is judged as an error.
  // console.log('response!!11' + JSON.stringify(res))


  if (res.msgcde === '0000') {
    return response;
  } else if (res.msgcde === 'E003' || res.msgcde === 'E006') {
    // 登录超时
    store.dispatch('user/logout').then(function () {
      router.push({
        path: '/login'
      });
    });
    Message({
      message: res.msg,
      type: 'error',
      duration: 3 * 1000
    });
    return response.data;
  } else if (res.msgcde === 'E005') {
    // 账号异地登录
    Message({
      message: res.msg,
      type: 'error',
      duration: 3 * 1000
    });
    store.dispatch('user/logout').then(function () {
      router.push({
        path: '/login'
      });
    });
  } else if (res.msgcde === 'E999') {
    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    Message({
      message: res.msg || res.msgcde || 'Error',
      type: 'error',
      duration: 5 * 1000
    });
    return Promise.reject(new Error(res.message || 'Error'));
  }
}, function (error) {
  // console.log('error: ' + error) // for debug
  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;