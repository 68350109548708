var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        { staticClass: "pageClass" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  attrs: { data: _vm.tableData }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "nftTypeName",
                      label: "藏品名称",
                      "min-width": "8%",
                      width: "216"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "issueMember1",
                      label: "发行方",
                      "min-width": "8%",
                      width: "176"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realIssueTime",
                      label: "发行日期",
                      "min-width": "6%",
                      formatter: _vm.formateDate,
                      width: "116"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "toIssueAmount",
                      label: "发行数量",
                      "min-width": "5%",
                      width: "80"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "nftLabel1",
                      label: "标签",
                      "min-width": "6%",
                      width: "112"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.nftLabel1,
                                    expression: "scope.row.nftLabel1"
                                  }
                                ],
                                staticClass: "labelStyle"
                              },
                              [_vm._v(_vm._s(scope.row.nftLabel1))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "deptName",
                      label: "部门",
                      "min-width": "7%",
                      width: "128"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "teamName",
                      label: "团队",
                      "min-width": "7%",
                      width: "128"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contactName",
                      label: "联系人",
                      "min-width": "7%",
                      width: "88"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "contactPhone",
                      label: "联系电话",
                      "min-width": "7%",
                      width: "112"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realName1",
                      label: "经办人",
                      "min-width": "7%",
                      width: "96"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "handleTime1",
                      label: "经办时间",
                      "min-width": "7%",
                      formatter: _vm.formateDate,
                      width: "112"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realName2",
                      label: "复核",
                      "min-width": "7%",
                      width: "96"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "handleTime2",
                      label: "复核时间",
                      "min-width": "7%",
                      formatter: _vm.formateDate,
                      width: "112"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "issueState",
                      label: "发行状态",
                      "min-width": "7%",
                      width: "200"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.issueState === "00"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: { size: "mini", type: "warning" }
                                      },
                                      [_vm._v("待经办")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          "font-size": "12px!important"
                                        },
                                        attrs: { type: "text" }
                                      },
                                      [_vm._v("复核意见")]
                                    ),
                                    _vm._v(" "),
                                    scope.row.opinion
                                      ? _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "light",
                                              content: "" + scope.row.opinion,
                                              placement: "top",
                                              "popper-class": "test-tooltip"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-warning-outline",
                                              staticStyle: { color: "#325AAF" }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.issueState === "01"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: { size: "mini", type: "danger" }
                                      },
                                      [_vm._v("待复核")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.issueState === "02"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      { attrs: { size: "mini", type: "" } },
                                      [_vm._v("上链中")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          "font-size": "12px!important"
                                        },
                                        attrs: { type: "text" }
                                      },
                                      [_vm._v("上链进度")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "light",
                                          "popper-class": "test-tooltip",
                                          content:
                                            "上链总数为" +
                                            (_vm.issueProcessData[
                                              scope.$index
                                            ] &&
                                              _vm.issueProcessData[scope.$index]
                                                .toIssueAmount) +
                                            "，当前上链" +
                                            (_vm.issueProcessData[
                                              scope.$index
                                            ] &&
                                              _vm.issueProcessData[scope.$index]
                                                .totallnBlockChain),
                                          placement: "top"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-warning-outline",
                                          staticStyle: { color: "#325AAF" }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.issueState === "03"
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: { size: "mini", type: "success" }
                                      },
                                      [_vm._v("已发行")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      "min-width": "11%",
                      width: "216",
                      fixed: "right"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "button",
                              {
                                staticClass: "btn-plain",
                                attrs: {
                                  disabled: scope.row.issueState !== "00"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleIssue(scope.row)
                                  }
                                }
                              },
                              [_vm._v("发行")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.issueState === "00" &&
                                      scope.row.opinion,
                                    expression:
                                      "scope.row.issueState === '00' && scope.row.opinion"
                                  }
                                ],
                                staticClass: "btn-plain",
                                on: {
                                  click: function($event) {
                                    return _vm.editIssue(scope.row)
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.issueState === "01",
                                    expression: "scope.row.issueState === '01'"
                                  }
                                ],
                                staticClass: "btn-plain",
                                attrs: {
                                  disabled: scope.row.issueState !== "01"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.approveIssue(scope.row)
                                  }
                                }
                              },
                              [_vm._v("复核")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-footer",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                height: "auto"
              }
            },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": 10,
                  layout: "total,sizes, prev, pager, next, jumper",
                  total: _vm.tableData.length
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "发行藏品",
                visible: _vm.issueDialog,
                width: "29%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.issueDialog = $event
                }
              }
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "font-size": "16px",
                    "line-height": "24px",
                    "font-weight": "400",
                    "text-align": "center"
                  }
                },
                [
                  _vm._v(
                    "你将发行“" +
                      _vm._s(_vm.fileName) +
                      "”藏品，一旦发行后，藏品名称、藏品本体、发行数量无法修改，且会立即和区块链同步，请务必确认信息无误后点击发行。"
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-top": "-30px"
                  },
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-pri",
                      staticStyle: {
                        height: "36px",
                        padding: "7px 32px",
                        "font-size": "16px"
                      },
                      on: { click: _vm.handleApprove }
                    },
                    [_vm._v("同意")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-plain",
                      staticStyle: {
                        height: "36px",
                        padding: "7px 32px",
                        "font-size": "16px"
                      },
                      on: {
                        click: function($event) {
                          _vm.issueDialog = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "发行藏品",
                visible: _vm.approveDialog,
                width: "35%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.approveDialog = $event
                }
              }
            },
            [
              _c("div", { staticClass: "titleStyle" }, [_vm._v("基本信息")]),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 5 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "nameStyle" }, [
                      _vm._v("藏品名称：")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "showStyle" }, [
                      _vm._v(_vm._s(_vm.approveForm.nftTypeName))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 5 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "nameStyle" }, [
                      _vm._v("发行方：")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "showStyle" }, [
                      _vm._v(_vm._s(_vm.approveForm.issueMember1))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 5 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "nameStyle" }, [
                      _vm._v("发行日期：")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "showStyle" }, [
                      _vm._v(
                        _vm._s(
                          _vm.approveForm.realIssueTime &&
                            _vm.approveForm.realIssueTime.split(" ")[0]
                        )
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 5 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "nameStyle" }, [
                      _vm._v("发行数量：")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "showStyle" }, [
                      _vm._v(_vm._s(_vm.approveForm.toIssueAmount))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 5 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "nameStyle" }, [_vm._v("标签：")])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.approveForm.nftLabel1,
                            expression: "approveForm.nftLabel1"
                          }
                        ],
                        staticClass: "showStyle"
                      },
                      [
                        _c("span", { staticClass: "labelStyle" }, [
                          _vm._v(_vm._s(_vm.approveForm.nftLabel1))
                        ])
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 5 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "nameStyle",
                        staticStyle: { "margin-top": "7%" }
                      },
                      [_vm._v("发行部门及团队：")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c("div", { staticClass: "showStyle" }, [
                      _vm._v(
                        _vm._s(_vm.approveForm.deptName) +
                          " " +
                          _vm._s(_vm.approveForm.teamName)
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 5 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "nameStyle",
                        staticStyle: { "margin-top": "8%" }
                      },
                      [_vm._v("联系人：")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "showStyle" }, [
                      _vm._v(_vm._s(_vm.approveForm.contactName))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                { attrs: { gutter: 5 } },
                [
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      {
                        staticClass: "nameStyle",
                        staticStyle: { "margin-top": "8%" }
                      },
                      [_vm._v("联系电话：")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "showStyle" }, [
                      _vm._v(_vm._s(_vm.approveForm.contactPhone))
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "titleStyle",
                  staticStyle: { "margin-top": "3%" }
                },
                [_vm._v("藏品信息")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "demo-image" },
                _vm._l(_vm.approveImg, function(img, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("el-image", {
                        staticStyle: {
                          width: "128px",
                          height: "128px",
                          "background-color": "#f0f0f0"
                        },
                        attrs: { src: img.url, fit: "contain" }
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "demon" }, [
                        _vm._v(_vm._s(img.name))
                      ])
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "left",
                    "margin-top": "-4%"
                  },
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-pri",
                      on: {
                        click: function($event) {
                          return _vm.approve("YES")
                        }
                      }
                    },
                    [_vm._v("同意发行")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn-pri", on: { click: _vm.handleBack } },
                    [_vm._v("退回经办")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn-pri",
                      on: { click: _vm.handlePreview }
                    },
                    [_vm._v("预览")]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "反馈复核意见",
                visible: _vm.feedbackDialog,
                width: "25%",
                "before-close": _vm.handleClose
              },
              on: {
                "update:visible": function($event) {
                  _vm.feedbackDialog = $event
                }
              }
            },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "在这里输入复核反馈意见",
                  rows: 4
                },
                model: {
                  value: _vm.opinion,
                  callback: function($$v) {
                    _vm.opinion = $$v
                  },
                  expression: "opinion"
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "left",
                    "margin-top": "-30px"
                  },
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn-pri",
                      on: {
                        click: function($event) {
                          return _vm.approve("NO")
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }