//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { homeData } from '@/api/user';
import store from '@/store';
import CountTo from 'vue-count-to';
import HomePageAnimation from '@/assets/homePage/homePageAnimation';
export default {
  components: {
    CountTo: CountTo,
    HomePageAnimation: HomePageAnimation
  },
  data: function data() {
    return {
      loading: true,
      countTimer: null,
      countStart1: 0,
      countEnd1: 0,
      countStart2: 0,
      countEnd2: 0,
      countStart3: 0,
      countEnd3: 0,
      countStart4: 0,
      countEnd4: 0
    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.loading) this.init();
  },
  activated: function activated() {
    if (this.loading) this.init();
  },
  beforeDestroy: function beforeDestroy() {
    if (this.countTimer != null) {
      clearInterval(this.countTimer);
      this.countTimer = null;
    }
  },
  methods: {
    init: function init() {
      var _this = this;

      Object.assign(this.$data, this.$options.data());
      homeData().then(function (res) {
        if (res.data.msgcde === '0000') {
          if (_this.countEnd1 > 0) {
            _this.countStart1 = _this.countEnd1;
          }

          if (_this.countEnd2 > 0) {
            _this.countStart2 = _this.countEnd2;
          }

          if (_this.countEnd3 > 0) {
            _this.countStart3 = _this.countEnd3;
          }

          if (_this.countEnd4 > 0) {
            _this.countStart4 = _this.countEnd4;
          }

          _this.countEnd1 = res.data.wxusercnt;
          _this.countEnd2 = res.data.cdcnt;
          _this.countEnd3 = res.data.actcnt;
          _this.countEnd4 = res.data.srcnt;
          _this.loading = false;
        } else {
          _this.$message.error(res.data.msg);
        }
      }).catch(function () {});
    },
    goTo: function goTo(flag) {
      var rightid = store.getters.rightIds;
      var i = 0;

      for (i; i < rightid.length; i++) {
        if (rightid[i] === 1 && flag === '1') {
          this.$router.push('/crowdInsight/officialAccountsUser');
          return;
        }

        if (rightid[i] === 6 && flag === '3') {
          this.$router.push('/marketStrategy/sMarketStrategy');
          return;
        }

        if (rightid[i] === 6 && flag === '5') {
          this.$router.push('/marketStrategy/addSMarketStrategy');
          return;
        }

        if (rightid[i] === 6 && flag === '6') {
          this.$router.push('/marketStrategy/cMarketStrategy/addnewrules1');
          return;
        }

        if (rightid[i] === 6 && flag === '7') {
          this.$router.push('/marketStrategy/cMarketStrategy/addNewMessage');
          return;
        }

        if (rightid[i] === 7 && flag === '3') {
          this.$router.push('/marketStrategy/sMarketStrategy');
          return;
        }

        if (rightid[i] === 8 && flag === '2') {
          this.$router.push('/marketActivity/allianceOrg');
          return;
        }

        if (rightid[i] === 8 && flag === '4') {
          this.$router.push('/marketActivity/addOrg');
          return;
        }
      }

      if (i === rightid.length) this.$message.warning('您暂无此权限');
    }
  }
};