var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        { staticClass: "pageClass" },
        [
          _c(
            "el-main",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  attrs: { data: _vm.tableData }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "nftTypeName",
                      label: "藏品名称",
                      "min-width": "8%",
                      width: "216"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "issueMember1",
                      label: "发行方",
                      "min-width": "8%",
                      width: "176"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "realIssueTime",
                      label: "发行日期",
                      "min-width": "6%",
                      formatter: _vm.formateDate,
                      width: "112"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "toIssueAmount",
                      label: "发行数量",
                      "min-width": "5%",
                      width: "80"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "nftLabel1",
                      label: "标签",
                      "min-width": "6%",
                      width: "112"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: scope.row.nftLabel1,
                                    expression: "scope.row.nftLabel1"
                                  }
                                ],
                                staticClass: "labelStyle"
                              },
                              [_vm._v(_vm._s(scope.row.nftLabel1))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "deptName",
                      label: "部门",
                      "min-width": "7%",
                      width: "128"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "teamName",
                      label: "团队",
                      "min-width": "7%",
                      width: "128"
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cntAll",
                      label: "兑换码总数",
                      "min-width": "6%",
                      "render-header": _vm.renderHeader,
                      width: "112"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#246DFF",
                                  "font-weight": "400"
                                }
                              },
                              [
                                _c("span", [_vm._v(_vm._s(scope.row.cntAll))]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-download",
                                  on: {
                                    click: function($event) {
                                      return _vm.downloads(
                                        scope.row.seqno,
                                        "ALL"
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cntInactive",
                      label: "待激活兑换码",
                      "min-width": "8%",
                      "render-header": _vm.renderHeader,
                      width: "128"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#246DFF",
                                  "font-weight": "400"
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.cntInactive))
                                ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-download",
                                  on: {
                                    click: function($event) {
                                      return _vm.downloads(
                                        scope.row.seqno,
                                        "INACTIVE"
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cntValid",
                      label: "有效兑换码",
                      "min-width": "8%",
                      "render-header": _vm.renderHeader,
                      width: "112"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#246DFF",
                                  "font-weight": "400"
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.cntValid))
                                ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-download",
                                  on: {
                                    click: function($event) {
                                      return _vm.downloads(
                                        scope.row.seqno,
                                        "VALID"
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cntInvalid",
                      label: "失效兑换码",
                      "min-width": "8%",
                      "render-header": _vm.renderHeader,
                      width: "112"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#246DFF",
                                  "font-weight": "400"
                                }
                              },
                              [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.cntInvalid))
                                ]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-download",
                                  on: {
                                    click: function($event) {
                                      return _vm.downloads(
                                        scope.row.seqno,
                                        "INVALID"
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "cntUsed",
                      label: "已兑换兑换码",
                      "min-width": "8%",
                      "render-header": _vm.renderHeader,
                      width: "128"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#246DFF",
                                  "font-weight": "400"
                                }
                              },
                              [
                                _c("span", [_vm._v(_vm._s(scope.row.cntUsed))]),
                                _vm._v(" "),
                                _c("i", {
                                  staticClass: "el-icon-download",
                                  on: {
                                    click: function($event) {
                                      return _vm.downloads(
                                        scope.row.seqno,
                                        "USED"
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", width: "212" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "button",
                              {
                                staticClass: "btn-plain",
                                staticStyle: {
                                  padding: "3px 14px",
                                  height: "28px",
                                  "font-size": "14px"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.createDialog(scope.row)
                                  }
                                }
                              },
                              [_vm._v("生成兑换码")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "light",
                                  content:
                                    "待生成" +
                                    scope.row.cntInactive +
                                    "个兑换码",
                                  placement: "top",
                                  "popper-class": "test-tooltip"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-warning-outline",
                                  staticStyle: {
                                    color: "#325AAF",
                                    "font-weight": "400"
                                  }
                                })
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-footer",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "justify-content": "center",
                height: "auto"
              }
            },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": 10,
                  layout: "total,sizes, prev, pager, next, jumper",
                  total: _vm.tableData.length
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "生成兑换码",
            visible: _vm.dialogVisible,
            width: "29%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "span",
            { staticStyle: { display: "flex", "justify-content": "center" } },
            [
              _vm._v(
                "目前可生成" +
                  _vm._s(_vm.cntInactive) +
                  "个“" +
                  _vm._s(_vm.nftTypeName) +
                  "”兑换码，是否生成？"
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { display: "flex", "justify-content": "center" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "button",
                { staticClass: "btn-pri", on: { click: _vm.confirm } },
                [_vm._v("确认")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-plain",
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }