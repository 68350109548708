import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import store from '@/store';
import { queryWhiteListFileByPage, approveWhiteListFile, queryAllNftTypeByPage } from "../../api/digitalCollections";
import { Message } from 'element-ui';
export default {
  name: 'WhiteList',
  components: {},
  data: function data() {
    var _this = this;

    var validateFiles = function validateFiles(rule, value, callback) {
      var files = _this.$refs.upload.uploadFiles;

      if (!files.length) {
        return callback(new Error('必须选择一个文件上传！'));
      }

      callback();
    };

    return {
      loading: true,
      currentPage: 1,
      // 当前页是1
      tableData: [],
      dialogVisible: false,
      // 确认弹框
      feedbackDialog: false,
      // 反馈意见弹窗
      addWhiteListDialog: false,
      // 新增白名单弹窗
      fileName: '',
      // 白名单文件名
      seqno: '',
      // 白名单序列号
      opinion: '',
      // 复核意见
      addForm: {
        issueSeqno: '',
        file: ''
      },
      rules: {
        issueSeqno: [{
          required: true,
          message: '请选择藏品类型',
          trigger: 'change'
        }],
        fileName: [{
          required: true,
          validator: validateFiles
        }]
      },
      nftTypeNameList: [],
      // 藏品类型
      fileList: [],
      // 文件列表
      headers: {},
      // 上传请求头
      showFlag: false // 控制上传tip与按钮显示

    };
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.loading) this.init();
  },
  activated: function activated() {
    if (this.loading) this.init();
  },
  beforeDestroy: function beforeDestroy() {},
  methods: {
    init: function init() {
      var _this2 = this;

      queryWhiteListFileByPage({
        fileStatus: "",
        pageNo: 1,
        pageSize: 100
      }).then(function (res) {
        _this2.tableData = res.result.content;
        _this2.loading = false;
      }).catch(function (err) {
        console.log(err, 'err');
      });
      this.headers['token'] = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2NDEzMjI0IiwiZXhwIjoxNjc0MDEzMDA0LCJpYXQiOjE2NzQwMDk0MDR9.MoTKP-A67Ypu9pIK5etugdNz-8A3OjI0Do9DWlP3ieU';
    },
    handleSizeChange: function handleSizeChange(val) {
      console.log("\u6BCF\u9875 ".concat(val, " \u6761"));
    },
    handleCurrentChange: function handleCurrentChange(val) {
      console.log("\u5F53\u524D\u9875: ".concat(val));
    },
    downdownloads: function downdownloads(val) {
      // const returl = `/${consts.UPLOAD_FILE_URL}/${val.fileUrl}`
      var returl = "http://22.189.50.152:30080/".concat(val.fileUrl);
      console.log(returl, 'returl是啥');
      window.location = returl;
    },
    addDialog: function addDialog() {
      var _this3 = this;

      this.addWhiteListDialog = true;
      var params = {
        displayStatus: '',
        teamID: this.$store.state.user.teamID,
        deptId: this.$store.state.user.deptId,
        pageNo: 1,
        pageSize: 100
      };
      queryAllNftTypeByPage(params).then(function (res) {
        if (res.code === 200) {
          _this3.nftTypeNameList = res.result.content;
        }
      });
    },
    addWhiteList: function addWhiteList(val) {
      var _this4 = this;

      this.$refs.addForm.validate(function (valid) {
        if (valid) {
          _this4.$refs.upload.submit();
        }
      });
    },
    approveDialog: function approveDialog(val) {
      this.dialogVisible = true;
      this.fileName = val.fileName;
      this.seqno = val.seqno;
    },
    showFeedback: function showFeedback() {
      this.feedbackDialog = true;
    },
    approveFeedback: function approveFeedback(val) {
      var _this5 = this;

      var params = {
        seqno: this.seqno,
        approveFlag: val,
        opinion: this.opinion
      };
      approveWhiteListFile(params).then(function (res) {
        Message({
          message: res.message,
          type: 'success',
          duration: 5 * 1000
        });
        _this5.dialogVisible = false;
        _this5.feedbackDialog = false;
      }).catch(function (err) {
        console.log(err, 'err');
      });
      this.init();
    },
    // handlePreview(file) {
    //   console.log(file)
    // },
    handleExceed: function handleExceed(files, fileList) {
      this.$message.warning('只能上传 1 个文件');
    },
    // 文件状态改变时触发(包含上传文件时的状态改变)
    handleChange: function handleChange(file, fileList) {
      var extention = file.name.substring(file.name.lastIndexOf('.') + 1);

      if (extention !== 'xlsx' && extention !== 'xls' && extention !== 'xlsm' && extention !== 'XLS' && extention !== 'XLSX' && extention !== 'XLSM') {
        if (fileList.length <= 1) {
          this.$refs.upload.clearFiles();
          fileList.length = 0;
          this.$message.warning('仅支持上传文件后缀名为xlsx、xls或xlsm的Excel文件！');
        } else {
          fileList.splice(fileList.indexOf(file), 1);
          this.$message.warning('仅支持上传文件后缀名为xlsx、xls或xlsm的Excel文件！');
          fileList.length = 1;
        }
      }

      if (fileList.length > 1) {
        this.$message.warning('仅支持上传一个Excel文件！');
        fileList.splice(fileList.indexOf(file), 1);
      }

      if (fileList.length >= 1) {
        this.showFlag = true;
      }
    },
    handleRemove: function handleRemove(file, fileList) {
      this.file = file;

      if (fileList.length === 0) {
        this.$message.warning("请至少上传一个文件！");
        this.showFlag = false;
      }
    },
    // 文件上传成功提示
    successMethod: function successMethod(res) {
      if (res.success === true && res.code === 200) {
        this.$message.success('上传成功');
        this.showFlag = false;
        this.addWhiteListDialog = false;
        this.$refs['addForm'].resetFields();
        this.$refs.upload.clearFiles();
        this.init();
      } else {
        this.$message.error('上传失败，' + res.message);
        this.showFlag = false;
        this.$refs.upload.clearFiles();
        this.$refs['addForm'].resetFields();
      }
    },
    // 文件上传失败提示
    failMethod: function failMethod(res) {
      this.$refs.upload.clearFiles();
      this.showFlag = false;
      this.$message({
        message: res.message,
        type: 'error',
        duration: 3 * 1000
      });
    }
  }
};