import request from '@/utils/request';
import urls from "../config/BAAS_URL";
export function login(params) {
  return request({
    url: urls.BAAS_login,
    method: 'POST',
    data: params
  });
}
export function getInfo(params) {
  return request({
    url: urls.BAAS_getUserInfo,
    method: 'POST',
    data: params
  });
}
export function logout() {
  return request({
    url: urls.BAAS_logout,
    method: 'POST'
  });
} // 迭代44 icsp

export function homeData(params) {
  return request({
    url: urls.HOME_DATA,
    method: 'POST',
    data: params
  });
}