import _objectSpread from "D:/code/icsp-web-frame/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/web.dom.iterable";
import { asyncRouterMap, constantRouterMap } from '@/router';
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */

function hasPermission(roles, route) {
  // if (route.meta && route.meta.roles) {
  //   return roles.role.some(role => route.meta.roles.includes(role))
  // } else {
  //   return true
  // }
  if (route.meta && route.meta.roles) {
    for (var i in route.meta.roles) {
      if (roles.indexOf(route.meta.roles[i]) >= 0) {
        return true;
      }
    }

    return false;
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */


export function filterAsyncRoutes(asyncRouterMap, roles) {
  var res = [];
  asyncRouterMap.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (hasPermission(roles, tmp)) {
      if (tmp.children && tmp.children.length) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }

      res.push(tmp);
    }
  });
  return res; // const accessedRouters = asyncRouterMap.filter(route => {
  //   if (hasPermission(roles, route)) {
  //     if (route.children && route.children.length) {
  //       route.children = filterAsyncRoutes(route.children, roles)
  //     }
  //     return true
  //   }
  //   return false
  // })
  // return accessedRouters
}
var state = {
  routes: constantRouterMap,
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    var wholeRouters = constantRouterMap;
    state.routes = wholeRouters.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    commit('SET_ROUTES', []); // console.log('generateRoutes')

    return new Promise(function (resolve) {
      var myroute = eval(roles.routerMap); // console.log(myroute, 'generateRoutes.myroute')

      var accessedRouters;
      console.log(asyncRouterMap);
      accessedRouters = filterAsyncRoutes(asyncRouterMap, myroute); // console.log('accessedRouters')
      // console.log(accessedRouters)

      commit('SET_ROUTES', accessedRouters);
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};