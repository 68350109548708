exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"menuText": "#d9ffff",
	"menuActiveText": "#2DA4FF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#3157AF",
	"menuHover": "#06256E",
	"subMenuBg": "#1A3E92",
	"subMenuHover": "#06256E",
	"sideBarWidth": "210px"
};